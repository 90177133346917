<template>
    <!--我发布的服务，别人购买的订单-->
    <rsck-page padding="0">
        <tab2 @tab-switch="tabSwitch" :is-show-line="false" class="tab-container">
            <nut-tab-panel :tab-title="item.label" v-for="(item,index) in catalog" :key="index">
                <tab @tab-switch="tabSwitch2" :is-show-line="false">
                    <nut-tab-panel :tab-title="item2.label" v-for="(item2,index2) in item.children" :key="index2">
                        <rsck-refresh :ref="'ref'+'_'+index+'_'+index2" :id="'id_'+index+'_'+index2" v-if="showResult"
                                      @onPullDown="onPullDown"
                                      @onPullUp="onPullUp"
                                      :height="scrollHeight" :specialStyles="{top:menuHeight+'px'}"
                                      padding="0">
                            <div @click="goToOrderDetail(item.value,dataItem)" class="order-info-container"
                                 v-for="(dataItem,dataIndex) in dataList[index][index2]">
                                <div class="order-info-left">
                                    <img :src="dataItem.cover_path"/>
                                </div>
                                <div class="order-info-right">
                                    <div class="order-title">
                                        <div class="title">{{dataItem.title}}</div>
                                        <div class="action">
                                            {{dataItem.status_name}}
                                        </div>
                                    </div>
                                    <div class="order-deduct">
                                        人生券抵扣{{dataItem.use_credit}}元
                                    </div>
                                    <div class="order-tags">
                                        <div class="tags">{{dataItem.classify_title}}</div>
                                    </div>
                                    <div class="order-rebate">
                                        <span>¥</span>{{dataItem.rebate_amount}}元
                                    </div>
                                </div>
                            </div>
                        </rsck-refresh>
                    </nut-tab-panel>
                </tab>
            </nut-tab-panel>
        </tab2>

        <nut-drag direction="y" :style="{ right: '0px', bottom: '100px' }">
            <mall-fixed-nav-v3 @selected="selected"></mall-fixed-nav-v3>
        </nut-drag>
    </rsck-page>
</template>

<script>
  import RsckPage from '@/components/common/rsck-page'
  import Tab2 from '@/components/tab/Tab2'
  import Tab from '@/components/tab/Tab'
  import RsckRefresh from '@/components/common/rsck-refresh'
  import MallFixedNavV3 from '@/components/nav/mall-fixed-nav-v3'

  export default {
    name: 'my-services-orders',
    components: {MallFixedNavV3, RsckRefresh, Tab, Tab2, RsckPage},
    data () {
      return {
        catalog: [
          {
            label: '闲置二手',
            value: 1,
            children: [
              {label: '全部订单', value: 100},
              {label: '进行中', value: 1},
              {label: '已完成', value: 3},
            ]
          },
          {
            label: '社区服务',
            value: 2,
            children: [
              {label: '全部订单', value: 100},
              {label: '进行中', value: 1},
              {label: '已完成', value: 3},
            ]
          }
        ],
        menuHeight: 0,
        scrollHeight: 0,
        showResult: false,
        outSideIndex: 0,//外部的按钮下标
        innerSideIndex: [0, 0],//内部的按钮下标,
        dataList: [],//数据
        page: [],//页码数组
        isLoading: [],//是否正在加载数据
        hasMore: [],//是否还有更多数据
        canContinue: true
      }
    },
    mounted () {
      this.menuHeight = document.querySelector('.tab-container').clientHeight
      this.scrollHeight = window.innerHeight - (this.menuHeight * 2)
      this.showResult = true
      document.title = '我的服务订单'
      let dataList = [], page = [], isLoading = [], hasMore = []
      this.catalog.forEach((outItem, outIndex) => {
        if (typeof dataList[outIndex] == 'undefined') {
          dataList[outIndex] = []
          page[outIndex] = []
          isLoading[outIndex] = []
          hasMore[outIndex] = []
        }
        outItem.children.forEach((innerItem, innerIndex) => {
          if (typeof dataList[outIndex][innerIndex] == 'undefined') {
            dataList[outIndex][innerIndex] = []
            page[outIndex][innerIndex] = 1
            isLoading[outIndex][innerIndex] = false
            hasMore[outIndex][innerIndex] = true
          }
        })
      })

      this.dataList = dataList
      this.page = page
      this.isLoading = isLoading
      this.hasMore = hasMore

      this.getOrderList()
    },
    methods: {
      selected () {
        this.$utils.debounce(
          () => {
            this.$platform.wxsdk.wxRoute({
              type: 'navigateTo',
              url: '/web/scancode/services',
            })
          },
          1000,
          false
        )
      },
      tabSwitch (e) {
        if (this.outSideIndex != e) {
          this.outSideIndex = e
        }
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]

        this.$forceUpdate()
        if (this.dataList[outSideIndex][innerSideIndex].length == 0) {
          this.getOrderList().then(() => {}).catch(() => {
            setTimeout(() => {
              let ref = this.getRefs()
              this.$refs[ref][0].endPullUp(true)
            },500)
          })
        }
      },
      tabSwitch2 (e) {
        let outSideIndex = this.outSideIndex
        if (this.innerSideIndex != e) {
          this.innerSideIndex[outSideIndex] = e
        }
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        this.$forceUpdate()
        if (this.dataList[outSideIndex][innerSideIndex].length == 0) {
          this.getOrderList().then(() => {}).catch(() => {
            setTimeout(() => {
              let ref = this.getRefs()
              this.$refs[ref][0].endPullUp(true)
            },500)
          })
        }
      },
      onPullDown () {
        //下拉刷新
        this.resetDataList()
        this.getOrderList().then(() => {
          let ref = this.getRefs()
          this.$refs[ref][0].endPullDown()
        }).catch(() => {
          let ref = this.getRefs()
          this.$refs[ref][0].endPullUp(true)
        })
      },
      onPullUp () {
        //上拉加载
        this.getOrderList().then(() => {
          let ref = this.getRefs()
          this.$refs[ref][0].endPullUp(!this.checkHasMore())
        }).catch(() => {
          let ref = this.getRefs()
          this.$refs[ref][0].endPullUp(true)
        })
      },
      getOrderList () {
        return new Promise((resolve, reject) => {
          let outSideIndex = this.outSideIndex
          let innerSideIndex = this.innerSideIndex[outSideIndex]
          let status = this.getStatus()
          let listTypeId = this.getListTypeId()
          let page = this.getPage()
          let isLoading = this.getIsLoading()

          if (!isLoading && this.checkHasMore()) {
            this.setIsLoading(true)
            this.$api.services.getSellerOrder({
              list_type_id: listTypeId,
              status: status,
              page: page,
              page_size: 10
            }).then(response => {
              this.setOrderList(response.data)
              this.setIsLoading(false)
              resolve(response)
            })
          } else {
            reject()
          }
        })
      },
      setOrderList (data) {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        let dataList = this.dataList[outSideIndex][innerSideIndex]

        if (data.length < 10) {
          this.hasMore[outSideIndex][innerSideIndex] = false
          setTimeout(() => {
            let ref = this.getRefs()
            this.$refs[ref][0].endPullUp(true)
          },500)
        }
        data.forEach(item => {
          dataList.push(item)
        })
        this.dataList[outSideIndex][innerSideIndex] = dataList
        this.page[outSideIndex][innerSideIndex]++
        this.$forceUpdate()
      },
      getStatus () {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        let data = this.catalog[outSideIndex].children
        let status = 100
        for (let i in data) {
          if (i == innerSideIndex) {
            status = data[i].value
          }
        }
        return status
      },
      getListTypeId () {
        let outSideIndex = this.outSideIndex
        return this.catalog[outSideIndex].value
      },
      getPage () {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        return this.page[outSideIndex][innerSideIndex]
      },
      getIsLoading () {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        return this.isLoading[outSideIndex][innerSideIndex]
      },
      setIsLoading (val) {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        this.isLoading[outSideIndex][innerSideIndex] = val
      },
      resetDataList () {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        this.dataList[outSideIndex][innerSideIndex] = []
        this.page[outSideIndex][innerSideIndex] = 1
        this.hasMore[outSideIndex][innerSideIndex] = true
        this.isLoading[outSideIndex][innerSideIndex] = false
      },
      getRefs () {
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex
        return 'ref_' + outSideIndex + '_' + innerSideIndex[outSideIndex]
      },
      checkHasMore () {
        //判断当前页签是否还有更多数据
        let outSideIndex = this.outSideIndex
        let innerSideIndex = this.innerSideIndex[outSideIndex]
        return this.hasMore[outSideIndex][innerSideIndex]

      },
      goToOrderDetail (listTypeId, item) {
        if (this.canContinue) {
          this.canContinue = false

          //跳转到订单详情页面
          let query = {
            listTypeId: listTypeId,
            id: item.id
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/services/my-services-orders-info?query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    /deep/ .nut-tab {
        padding: 0;
        border: none;
    }

    /deep/ .nut-tab-item {
        padding: 0;
        border: none;
        background: #f6f6f6;
        height: 0;
    }

    /deep/ .nut-tab-title {
        border: none;
    }

    .order-info-container {
        display: flex;
        background: #fff;
        box-shadow: 0px 1px 7px 2px rgba(115, 115, 115, 0.05);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        margin-top: 10px;
        padding: 15px;

        .order-info-left {
            width: 90px;
            height: 90px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .order-info-right {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .order-title {
                width: 100%;
                display: flex;
                margin-bottom: 9px;

                .title {
                    flex: 1;
                    margin-right: 5px;
                    color: #333333;
                    font-size: 14px;
                    height: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: bold;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    word-break: break-all;
                }

                .action {
                    width: 42px;
                    color: #FF8116;
                    font-size: 14px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: bold;
                }
            }

            .order-deduct {
                width: 100%;
                color: #F46A17;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                margin-bottom: 9px;
            }

            .order-tags {
                width: 100%;

                .tags {
                    border: #939393 solid 1px;
                    font-size: 12px;
                    color: #939393;
                    display: inline-block;
                    padding: 4px 7px;
                    font-family: PingFangSC-Regular, PingFang SC;
                }
            }

            .order-rebate {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                font-size: 14px;
                font-weight: bold;
                color: #C10000;
                font-family: PingFangSC-Semibold, PingFang SC;
                align-items: flex-end;

                span {
                    font-size: 10px;
                }
            }
        }
    }
</style>